import React, { useState } from "react";
import styles from "./PriceCard.module.css";
import Coin from "./../../../assets/svg/coin.svg";
import CheckIcon from "@mui/icons-material/Check";
import Click from "./../../../assets/png/click.png";
import Payme from "./../../../assets/png/payme.png";

const PriceCard = ({ oldPrice, newPrice, month, coin, permissions }) => {
  const [selectedPayment, setSelectedPayment] = useState("");

  // Handle radio button change 
  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);
  };

  return (
    <div className={styles.mainPriceCard}>
      <h2 className={styles.oldPrice}>
        {oldPrice} <span className={styles.oldPriceLine}>___________</span>
      </h2>
      <div className={styles.nowPrice}>
        <h1 className={styles.nowPriceSum}>
          {newPrice} <span className={styles.month}>{month}</span>
        </h1>
        <div className={styles.nowCoinBox}>
          <h2 className={styles.nowCoin}>{coin}</h2>
          <img src={Coin} alt="Coin" />
        </div>
      </div>

      <ul className={styles.listUnordered}>
        {permissions.map((permission, index) => (
          <li key={index}>
            <CheckIcon /> {permission}
          </li>
        ))}
      </ul>

      <div className={styles.payment}>
        <label className={styles.click}>
          <input
            type="radio"
            name="payment"
            value="click"
            checked={selectedPayment === "click"}
            onChange={handlePaymentChange}
          />
          <img src={Click} alt="Click" />
        </label>

        <label className={styles.payme}>
          <input
            type="radio"
            name="payment"
            value="payme"
            checked={selectedPayment === "payme"}
            onChange={handlePaymentChange}
          />
          <img src={Payme} alt="Payme" />
        </label>
      </div>

      <button className={styles.buyNow}>Buy Now</button>
    </div>
  );
};

export default PriceCard;
