import styles from "./Library.module.css";

const Library = () => {
  return (
    <>
      <div className={styles.backgroundMain}></div>
    </>
  );
};

export default Library;
