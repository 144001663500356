import { useState } from "react";
import styles from "./Price.module.css";
import PriceCard from "./PriceCard/PriceCard";

const Price = () => {
  const [activeItem, setActiveItem] = useState("starter");

  const permissions = {
    starter: ["University Information", "AI Assistant", "Smart Filter"],
    pro: [
      "University Information",
      "AI Assistant",
      "Smart Filter",
      "Mock Exam",
      "E-Library",
    ],
    advance: [
      "University Information",
      "AI Assistant",
      "Smart Filter",
      "Mock Exam",
      "E-Library",
      "Recommendation System",
      "Advanced Analytics",
    ],
  };

  const priceDetails = {
    starter: {
      oldPrice: "35000UZS",
      newPrice: "25000UZS",
      month: "3 months",
      coin: "50 + 7",
    },
    pro: {
      oldPrice: "45000UZS",
      newPrice: "30000UZS",
      month: "6 months",
      coin: "100 + 15",
    },
    advance: {
      oldPrice: "55000UZS",
      newPrice: "40000UZS",
      month: "12 months",
      coin: "150 + 20",
    },
  };

  const handleClick = (item) => {
    setActiveItem(item);
  };

  return (
    <div className={styles.mainPrice}>
      <div className={styles.container}>
        <div>
          <ul className={styles.tabList}>
            <li
              className={`${styles.tabItem} ${
                activeItem === "starter" ? styles.active : ""
              }`}
              onClick={() => handleClick("starter")}
            >
              Starter
            </li>
            <li
              className={`${styles.tabItem} ${
                activeItem === "pro" ? styles.active : ""
              }`}
              onClick={() => handleClick("pro")}
            >
              Pro
            </li>
            <li
              className={`${styles.tabItem} ${
                activeItem === "advance" ? styles.active : ""
              }`}
              onClick={() => handleClick("advance")}
            >
              Advance
            </li>
          </ul>
        </div>
        <div>
          <PriceCard
            oldPrice={priceDetails[activeItem].oldPrice}
            newPrice={priceDetails[activeItem].newPrice}
            month={priceDetails[activeItem].month}
            coin={priceDetails[activeItem].coin}
            permissions={permissions[activeItem]}
          />
        </div>
      </div>
    </div>
  );
};

export default Price;
