import React, { useState, useCallback, useEffect, useMemo } from "react";
import styles from "./Profile.module.css";
import EnglishFlag from "./../../assets/png/EnglishFlag.png";
import ProfileModal from "./ProfileModal/ProfileModal";
import { instance } from "../axios";

const Profile = () => {
  const [imageSrc, setImageSrc] = useState(EnglishFlag); // Assuming EnglishFlag is the default avatar
  const [imageFile, setImageFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [initialUserData, setInitialUserData] = useState({});
  const [inputValues, setInputValues] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    password: "",
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await instance.get("/users/me");
        const data = response.data;
        setUserData(data);
        setInitialUserData(data);

        setInputValues({
          first_name: data.first_name || "",
          last_name: data.last_name || "",
          phone_number: data.phone_number || "",
          password: "",
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleImageUpload = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
        setInputValues((prevInputValues) => ({
          ...prevInputValues,
          image: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const formData = new FormData();
      formData.append("first_name", inputValues.first_name);
      formData.append("last_name", inputValues.last_name);
      formData.append("phone_number", inputValues.phone_number);
      formData.append("password", inputValues.password);
      if (imageFile) {
        formData.append("image", imageFile);
      }

      try {
        await instance.put("/users/me", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setIsEditing(false);
        setInitialUserData(inputValues);
      } catch (error) {
        console.error("Error saving data:", error);
      }
    },
    [inputValues, imageFile]
  );

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleCancelEdit = useCallback(() => {
    setIsEditing(false);
    setInputValues(initialUserData);
  }, [initialUserData]);

  const handleInputChange = useMemo(() => {
    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          func(...args);
        }, delay);
      };
    };

    return debounce((event) => {
      const { name, value } = event.target;
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [name]: value,
      }));
    }, 300);
  }, []);

  const triggerFileInput = useCallback(() => {
    document.getElementById("fileInput").click();
  }, []);

  const handleLogOut = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleConfirmLogOut = useCallback(() => {
    localStorage.removeItem("token");
    window.location.href = "/";
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.mainProfile}>
          <h1 className={styles.headerH1}>Settings</h1>
        </div>
      </div>

      <div className={styles.basicProfile}>
        <div className={styles.mainProfile2}>
          <div className={styles.updateAvatarSection}>
            <h3 className={styles.profileName}>Edit profile</h3>
            <div className={styles.avatar}>
              <img src={imageSrc} alt="dataImage" className="rounded-full" />
            </div>
            <input
              id="fileInput"
              type="file"
              onChange={handleImageUpload}
              style={{ display: "none" }}
            />
            <div className={styles.nameAvatar}>
              <h1
                className={styles.userName}
              >{`${inputValues.first_name} ${inputValues.last_name}`}</h1>
              <button className={styles.btnAvatar} onClick={triggerFileInput}>
                Upload new avatar
              </button>
              <span className={styles.uploadRequirements}>
                image size should be until 1MB
              </span>
            </div>
            <button className={styles.logOut} onClick={handleLogOut}>
              Log Out
            </button>
          </div>
          <div className={styles.dataSection}>
            <div className={styles.dataSectionHeader}>
              <h2 className={styles.basic}>Basic Info</h2>
              <div className="flex justify-center items-center gap-[10px]">
                {isEditing ? (
                  <>
                    <button
                      className={styles.editbtn}
                      onClick={handleCancelEdit}
                    >
                      Cancel
                    </button>
                    <button className={styles.savebtn} onClick={handleSubmit}>
                      Save
                    </button>
                  </>
                ) : (
                  <button className={styles.editbtn} onClick={handleEdit}>
                    Edit
                  </button>
                )}
              </div>
            </div>
            <hr className="my-[20px] bg-[#E5E5E5] max-[600px]:hidden" />
            <form className={styles.formInput} onSubmit={handleSubmit}>
              <div>
                <label htmlFor="first_name" className="block">
                  FIRST NAME
                </label>
                <input
                  type="text"
                  name="first_name"
                  value={inputValues.first_name}
                  onChange={handleInputChange}
                  className={styles.input}
                  disabled={!isEditing}
                />
              </div>
              <div>
                <label htmlFor="last_name" className="block">
                  LAST NAME
                </label>
                <input
                  type="text"
                  name="last_name"
                  value={inputValues.last_name}
                  onChange={handleInputChange}
                  className={styles.input}
                  disabled={!isEditing}
                />
              </div>
              <div>
                <label htmlFor="password" className="block">
                  PASSWORD
                </label>
                <input
                  type="password"
                  name="password"
                  value={inputValues.password}
                  onChange={handleInputChange}
                  className={styles.input}
                  disabled={!isEditing}
                />
              </div>
              <div>
                <label htmlFor="phone_number" className="block">
                  PHONE NUMBER
                </label>
                <input
                  type="text"
                  name="phone_number"
                  value={inputValues.phone_number}
                  onChange={handleInputChange}
                  className={styles.input}
                  disabled={!isEditing}
                />
              </div>
            </form>
            <div className={styles.editSaveBtnDiv}>
              <button className={styles.editbtn} onClick={handleEdit}>
                Edit
              </button>
              <button className={styles.savebtn} onClick={handleSubmit}>
                Save
              </button>
            </div>
            <div className={styles.divMobileLogOut}>
              <button className={styles.logOutMobile} onClick={handleLogOut}>
                Log Out
              </button>
            </div>
          </div>
        </div>
      </div>

      <ProfileModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmLogOut}
      />
    </>
  );
};

export default Profile;
