import { useState, useEffect } from "react";
import { instance } from "../axios";
import styles from "./Register.module.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import CheckIcon from "@mui/icons-material/Check";
import FooterLogoMain from "./../../assets/svg/FooterLogo.svg";
import Success from "./../../assets/png/success.png";
import Avatar1 from "./../../assets/png/avatar1.png";
import Avatar2Female from "./../../assets/png/avatar2Female.png";
import Avatar3Female from "./../../assets/png/avatar3Female.png";
import Avatar1Male from "./../../assets/png/avatar1Male.png";
import Avatar2Male from "./../../assets/png/avatar2Male.png";
import Avatar3Male from "./../../assets/png/avatar3Male.png";

const avatars = [
  Avatar1,
  Avatar2Female,
  Avatar3Female,
  Avatar1Male,
  Avatar2Male,
  Avatar3Male,
];

const Register = () => {
  const [view, setView] = useState("signUp");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [accountCreated, setAccountCreated] = useState(false);
  const [profileCreated, setProfileCreated] = useState(false);
  const [finalStep, setFinalStep] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(avatars[0]);

  const [emailLogin, setEmailLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");

  const [fullName, setFullName] = useState("");
  const [classLevel, setClassLevel] = useState("");
  const [ielts, setIelts] = useState("");
  const [math, setMath] = useState("");
  const [value, setValue] = useState("");

  const handleSignUp = () => {
    setView("signUp");
  };

  const handleSignIn = () => {
    setView("signIn");
  };

  const handleCreateAccount = () => {
    if (!email || !password) {
      alert("Please fill in both Email address and Password fields.");
    } else if (!email.includes("@")) {
      alert("Please fill in Email address");
    } else if (!password) {
      alert("Please fill in Password");
    } else {
      setAccountCreated(true);
    }
  };

  const handleContinue = () => {
    if (!fullName || !value || !classLevel || !ielts || !math) {
      alert("Please fill in all fields");
    } else if (!fullName) {
      alert("Please fill in full Name");
    } else if (!value) {
      alert("Please fill in Phone Number");
    } else if (!classLevel) {
      alert("Please fill in Grade");
    } else if (!ielts) {
      alert("Please fill in IELTS score");
    } else if (!math) {
      alert("Please fill in Math Level");
    } else {
      setProfileCreated(true);
    }
  };

  const handleNext = async () => {
    if (selectedAvatar) {
      const userData = {
        email: email,
        password: password,
        first_name: fullName.split(" ")[0],
        last_name: fullName.split(" ")[1] || "",
        phone_number: value,
        grade: classLevel,
        english_score: ielts,
        math_score: math,
      };

      try {
        const response = await instance.post("users/register", userData);

        if (response.status === 201) {
          const { token } = response.data;

          localStorage.setItem("token", token);
          setFinalStep(true);
        } else {
          alert("Failed to register:");
        }
      } catch (response) {
        alert(`Error: ${response.response.data.errors.map((e) => e.message)}`);
      }
    } else {
      alert("Please select an avatar.");
    }
  };

  const handleAvatarSelect = (avatar) => {
    setSelectedAvatar(avatar);
  };

  const validatePassword = () => {
    const rules = [
      {
        test: password.length >= 8,
        message: "At least 8 characters",
      },
      {
        test: /[0-9]/.test(password) || /[!@#$%^&*]/.test(password),
        message: "Contains a number or symbol",
      },
      {
        test: !password
          .toLowerCase()
          .includes(email.toLowerCase().split("@")[0]),
        message: "Cannot contain your name or email address",
      },
      {
        test: password.length > 0,
        message: "Password Strength : Weak",
      },
    ];

    return rules.map((rule) => ({
      ...rule,
      valid: rule.test,
    }));
  };

  const passwordRules = validatePassword();

  const handleSignInSubmit = async () => {
    if (!emailLogin || !passwordLogin) {
      alert("Please fill in both Email address and Password fields.");
      return; // Exit the function to prevent making the request
    }

    try {
      const userData = {
        email: emailLogin,
        password: passwordLogin,
      };

      const response = await instance.post("users/login", userData);

      if (response.status === 200) {
        const { token } = response.data;
        // console.log(token);

        localStorage.setItem("token", token);
        window.location.href = "/";
      } else {
        alert("Failed to sign in: " + response.statusText);
      }
    } catch (error) {
      alert("An error occurred: " + error.message);
      console.error("Sign-in error:", error);
    }
  };

  useEffect(() => {
    if (finalStep) {
      const timer = setTimeout(() => {
        window.location.href = "/register";
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [finalStep]);

  return (
    <>
      <div className={styles.registerBackground}>
        <div className={styles.container}>
          <div className={styles.mainCard}>
            {!accountCreated ? (
              <>
                <div className={styles.title}>
                  <img src={FooterLogoMain} alt="FooterLogoMain" />
                  <h2 className={styles.titleHeading}>Welcome to Uni One</h2>
                </div>
                <div className={styles.btnRegisterLogin}>
                  <button
                    className={`${styles.register} ${
                      view === "signUp" ? styles.active : ""
                    }`}
                    onClick={handleSignUp}
                  >
                    Sign Up
                  </button>
                  <button
                    className={`${styles.login} ${
                      view === "signIn" ? styles.active : ""
                    }`}
                    onClick={handleSignIn}
                  >
                    Sign In
                  </button>
                </div>
                {view === "signUp" && (
                  <>
                    <form action="" className={styles.mainForm}>
                      <label htmlFor="email" className={styles.emailLabel}>
                        Email Id
                      </label>
                      <input
                        type="email"
                        className={styles.emailInput}
                        name="email"
                        value={email}
                        id="email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <label
                        htmlFor="password"
                        className={styles.passwordLabel}
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className={styles.passwordInput}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </form>
                    <ul className={styles.unorderedList}>
                      {passwordRules.map((rule, index) => (
                        <li
                          key={index}
                          className={`${styles.shouldList} ${
                            rule.valid ? styles.valid : styles.invalid
                          }`}
                        >
                          <CheckIcon /> {rule.message}
                        </li>
                      ))}
                    </ul>
                    <button
                      className={styles.createAccount}
                      onClick={handleCreateAccount}
                    >
                      Create Account
                    </button>
                  </>
                )}
                {view === "signIn" && (
                  <div>
                    <form action="" className={styles.mainForm}>
                      <label htmlFor="email" className={styles.emailLabel}>
                        Email Id
                      </label>
                      <input
                        type="email"
                        id="email"
                        className={styles.emailInput}
                        name="email"
                        value={emailLogin}
                        onChange={(e) => setEmailLogin(e.target.value)}
                        required
                      />
                      <label
                        htmlFor="password"
                        className={styles.passwordLabel}
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className={styles.passwordInput}
                        value={passwordLogin}
                        onChange={(e) => setPasswordLogin(e.target.value)}
                        required
                      />
                      <button
                        type="button"
                        className={styles.signIn}
                        onClick={handleSignInSubmit}
                      >
                        Sign In
                      </button>
                    </form>
                  </div>
                )}
              </>
            ) : (
              <>
                {!profileCreated ? (
                  <div className={styles.profileCreate}>
                    <div className={styles.createProfileTitle}>
                      <img src={FooterLogoMain} alt="FooterLogoMain" />
                      <h2 className={styles.titleHeading}>Uni One</h2>
                    </div>
                    <h3 className={styles.newProfile}>
                      Create your new profile
                    </h3>
                    <form action="" className={styles.mainFormProfileCreate}>
                      <label
                        htmlFor="fullName"
                        className={styles.mainStyleLabel}
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        id="fullName"
                        className={styles.mainInputStyle}
                        name="first_name"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        required
                      />
                      <label
                        htmlFor="phoneNumber"
                        className={styles.mainStyleLabel}
                      >
                        Phone-number
                      </label>
                      {/* <input
                        type="number"
                        name="phone_number"
                        className={styles.mainInputStyle}
                        value={phoneNumber}
                        /> */}
                      <PhoneInput
                        id="phoneNumber"
                        placeholder="Enter phone number"
                        value={value}
                        onChange={setValue}
                        className={styles.mainInputStyle}
                        required
                      />
                      <label htmlFor="class" className={styles.mainStyleLabel}>
                        Class
                      </label>
                      <select
                        name="grade"
                        id="class"
                        className={styles.mainInputStyle}
                        value={classLevel}
                        onChange={(e) => setClassLevel(e.target.value)}
                        required
                      >
                        <option value="">Select</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                      </select>
                      <label htmlFor="ielts" className={styles.mainStyleLabel}>
                        IELTS
                      </label>
                      <select
                        name="english_score"
                        id="ielts"
                        className={styles.mainInputStyle}
                        value={ielts}
                        onChange={(e) => setIelts(e.target.value)}
                        required
                      >
                        <option value="">Select</option>
                        <option value="5-5.5">5 - 5.5</option>
                        <option value="6-6.5">6 - 6.5</option>
                        <option value="7-7.5">7 - 7.5</option>
                        <option value="8-8.5">8 - 8.5</option>
                        <option value="9">9</option>
                      </select>
                      <label htmlFor="math" className={styles.mainStyleLabel}>
                        Math
                      </label>
                      <select
                        name="math_score"
                        id="math"
                        className={styles.mainInputStyle}
                        value={math}
                        onChange={(e) => setMath(e.target.value)}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Foundation">Foundation</option>
                        <option value="Foundation">Middle</option>
                        <option value="Foundation">Advanced</option>
                      </select>
                    </form>
                    <button
                      className={styles.continueButton}
                      onClick={handleContinue}
                    >
                      Continue
                    </button>
                  </div>
                ) : !finalStep ? (
                  <div className={styles.emojiProfile}>
                    <h1 className={styles.emojiProfileTitle}>
                      Customize your Profile
                    </h1>
                    <div>
                      <img
                        src={selectedAvatar}
                        alt="Selected Avatar"
                        className={styles.avatarDiv}
                      />
                    </div>
                    <h3 className={styles.emojiNameProfile}>{fullName}</h3>
                    <div className={styles.EmojiSelect}>
                      {avatars.map((avatar, index) => (
                        <div
                          key={index}
                          className={styles.avatarBox}
                          onClick={() => handleAvatarSelect(avatar)}
                        >
                          <img
                            src={avatar}
                            alt={`Avatar ${index + 1}`}
                            // className={styles.imageCircle}
                            className={`${styles.imageCircle} ${
                              selectedAvatar === avatar
                                ? styles.activeAvatar // Agar avatar tanlangan bo'lsa, class qo'shish
                                : ""
                            }`}
                          />
                        </div>
                      ))}
                    </div>
                    <button className={styles.nextButton} onClick={handleNext}>
                      Next
                    </button>
                  </div>
                ) : finalStep ? (
                  <>
                    <div className={styles.successfullyProfileCreate}>
                      <div className={styles.successProfileTitle}>
                        <img src={FooterLogoMain} alt="FooterLogoMain" />
                        <h1 className={styles.successProfileTitleHeading}>
                          Welcome to Uni One
                        </h1>
                      </div>
                      <img
                        src={Success}
                        alt="Success"
                        className={styles.success}
                      />
                      <h2 className={styles.finishCreateProfile}>
                        Registration Successful!
                      </h2>
                      <p className={styles.additionInfo}>
                        You will be redirected to the home page shortly.
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <h1>error</h1>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
