import React from "react";
import styles from "./WebSiteAccess.module.css";
import CheckIcon from "@mui/icons-material/Check";
import FooterLogo from "./../../assets/svg/webSiteAccessLogo.svg";

const WebSiteAccess = () => {
  return (
    <>
      <div className={styles.mainWebSiteAccess}>
        <div className={styles.container}>
          <h1 className={styles.webSiteAccessTitle}>Web-Site Access</h1>
          <p className={styles.webSiteAccessParagraph}>
            If you want to use the full capabilities of our web site, you need
            to register
          </p>
          <div className={styles.access}>
            <div className={styles.noRegister}>
              <h3 className={styles.noRegisterTitle}>No Register</h3>
              <p className={styles.noRegisterParagraph}>
                Limtless possibilites
              </p>
              <div className={styles.guest}>
                <h1 className={styles.guestTitle}>Guest</h1>
                <ul>
                  <li className={styles.listGuest}>
                    <CheckIcon /> Domestic University
                  </li>
                  <li className={styles.listGuest}>
                    <CheckIcon /> International University
                  </li>
                  <li className={styles.listGuest}>
                    <CheckIcon /> Chat with Uni One Assistant
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.afterRegistration}>
              <h3 className={styles.afterRegisterTitle}>After Registration</h3>
              <p className={styles.afterRegisterParagraph}>
                Limtless possibilites
              </p>
              <div className={styles.afterGuest}>
                <div className={styles.afterGuestMain}>
                  <img src={FooterLogo} alt="FooterLogo" />
                  <h1 className={styles.afterGuestTitle}>Uni One Members</h1>
                </div>
                <ul className={styles.unorderedAfterGuest}>
                  <li className={styles.listGuest}>
                    <CheckIcon /> Domestic University
                  </li>
                  <li className={styles.listGuest}>
                    <CheckIcon /> International University
                  </li>
                  <li className={styles.listGuest}>
                    <CheckIcon /> Smart Filter
                  </li>
                  <li className={styles.listGuest}>
                    <CheckIcon /> Chat with Uni One Assistant
                  </li>
                  <li className={styles.listGuest}>
                    <CheckIcon /> E-Library
                  </li>
                  <li className={styles.listGuest}>
                    <CheckIcon /> Recomendation System
                  </li>
                  <li className={styles.listGuest}>
                    <CheckIcon /> CD-Mock IELTS
                  </li>
                  <li className={styles.listGuest}>
                    <CheckIcon /> Profile Settings
                  </li>
                </ul>
                <div className={styles.btnRegister}>
                  <a href="/register">
                    <button className={styles.signUpForFree}>
                      Sign up for free
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebSiteAccess;
