import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./MathTest.module.css";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { instance } from "../../../axios";
import uzbekistanFlag from "./../../../../assets/webp/uzbekistan.webp";
import englishFlag from "./../../../../assets/png/EnglishFlag.png";
import russianFlag from "./../../../../assets/webp/russianFlag.webp";
import { Select, Space } from "antd";
import { LanguageContext } from "../../../../LanguageProvider";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CoinMain from "./../../../../assets/svg/CoinMain.svg";

const MathTest = () => {
  const { language, changeLanguage } = useContext(LanguageContext); //changeLanguage
  const { t, i18n } = useTranslation(); //changeLanguage

  const languageOptions = [
    {
      value: "en",
      label: "English",
      flag: englishFlag,
    },
    {
      value: "ru",
      label: "Russian",
      flag: russianFlag,
    },
    {
      value: "uz",
      label: "Uzbek",
      flag: uzbekistanFlag,
    },
  ];
  const [testData, setTestData] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    instance
      .get(`/exam/detail/${id}`)
      .then((res) => {
        const modifiedData = { ...res.data };
        const backendTime = res.data.time.split(":");
        const hours = parseInt(backendTime[0], 10);
        const minutes = parseInt(backendTime[1], 10);

        modifiedData.time =
          hours > 0
            ? `${hours} hour${hours > 1 ? "s" : ""} ${minutes} minute${
                minutes > 1 ? "s" : ""
              }`
            : `${minutes} minute${minutes > 1 ? "s" : ""}`;

        setTestData(modifiedData);
      })
      .catch((error) => {
        console.error("Error fetching mock tests:", error);
      });
  }, [id]);

  const handleLanguageChange = useCallback(
    (selectedLanguage) => {
      changeLanguage(selectedLanguage);
      i18n.changeLanguage(selectedLanguage);
    },
    [changeLanguage, i18n]
  ); //change language

  return (
    <div className={styles.container}>
      <div className={styles.main_box} key={testData.id}>
        <div className={styles.mathTest_navbar}>
          <a href="/mock">
            <h1>
              <KeyboardArrowLeftIcon /> Back
            </h1>
          </a>
          <div className={styles.coin_lang}>
            <div className={styles.coin_remember}>
              <h2>7 coin</h2>
              <img src={CoinMain} alt="CoinMain" />
            </div>
            <div className="max-[450px]:hidden">
              <Space wrap>
                <Select
                  defaultValue={language}
                  name="Language"
                  value={language}
                  onChange={handleLanguageChange}
                  options={languageOptions.map((option) => ({
                    value: option.value,
                    label: (
                      <Space>
                        <img
                          src={option.flag}
                          alt={option.label}
                          style={{ width: "20px", height: "20px" }}
                        />
                        {option.label}
                      </Space>
                    ),
                  }))}
                />
              </Space>
            </div>
          </div>
        </div>

        <div key={testData.id} className={styles.information}>
          <h1>{testData.title}</h1>
          <h3>{t("Test Information")}</h3>
          <ul>
            <li>This test includes the {testData.id} sections.</li>
            <li>
              The estimated time to complete this test is approximately{" "}
              {testData.time} times to complete
            </li>
          </ul>
          <p>Please confirm if you would like to continue.</p>
          <div className={styles.btn}>
            <button>
              <Link to={`/mock/confirm/${testData.id}/test`}>Confirm</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MathTest;
